import React from 'react';
import './MapPage.css';
import Map from '../components/Map';
import heroImage from '../assets/led.jpg';
import domtoimage from 'dom-to-image';
import footerImage from '../assets/footer.jpg';
import instrImage from '../assets/instr.jpg';
import { trackEvent } from '../components/FacebookPixel';

const MapPage = () => {
  const handleDownloadScreenshot = () => {
    const mapElement = document.querySelector('.map-bgz'); // Використовуємо контейнер, який містить карту і маркер

    domtoimage.toJpeg(mapElement, {
      quality: 1.0,
      scale: 3,
      style: {
        background: 'none', // Переконаємося, що фон прозорий, якщо це необхідно
        position: 'relative', // Забезпечуємо правильне позиціонування маркера
        zIndex: 1 // Переконаємося, що маркер відображається поверх карти
      },
      filter: (node) => {
        // Фільтруємо елементи, якщо потрібно видалити непотрібні елементи зі скріншоту
        return node.tagName !== 'BUTTON';
      }
    })
    .then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'map-screenshot.jpg';
      link.href = dataUrl;
      link.click();
    })
    .catch((error) => {
      console.error('Failed to capture map image:', error);
    });
  };

  const handleGoToOrder = () => {
    trackEvent('Lead');
    window.open('https://afflat3c1.com/trk/lnk/33F281F8-3D1F-45EE-8703-F155EB1ABD36/?o=18265&c=918277&a=723766&k=3561966F28D850D9EC594888A0D18431&l=19476&s1=map', '_blank');
  };

  const handleImageClick = () => {
    trackEvent('Lead');
  };

  return (
    <div className="map-page">
      <div className="hero">
        <img src={heroImage} alt="Background" className="hero-image"/>
        <div className="hero-content">
            <h1>Create a Lasting Memory in Just Minutes</h1>
            {/*<p>Personalize Your Gift with a Photo and a Heartfelt Message</p>*/} 
        </div>
    </div>

      <h2 className="section-title">Design Something Unique</h2>
      <hr className="divider"/>

      <h3 className="sub-title">Step 1: Select a Meaningful Location & Download the Screenshot</h3>
      <div className="map-bg">
      <div className="map-bgz">
      <div className="map-marker"></div>
      <Map />
      </div>
      </div>
      <button className="download-button" onClick={handleDownloadScreenshot}>Download Your Screenshot</button>
      <hr className="divider"/>

      <h3 className="sub-title">Step 2: Visit Online Store, Find the LED picture, Upload Your Screenshot, and Personalize Your Order with Names and a Custom Message</h3>
      <a href="https://afflat3c1.com/trk/lnk/33F281F8-3D1F-45EE-8703-F155EB1ABD36/?o=18265&c=918277&a=723766&k=3561966F28D850D9EC594888A0D18431&l=19476&s1=map" target="_blank" rel="noopener noreferrer" onClick={handleImageClick}>
        <img src={instrImage} alt="Footer" className="footer-image"/>
      </a>
      <button className="order-button" onClick={handleGoToOrder}>Shop Now</button>
      <div className="footer-image-container">
        <a href="https://afflat3c1.com/trk/lnk/33F281F8-3D1F-45EE-8703-F155EB1ABD36/?o=18265&c=918277&a=723766&k=3561966F28D850D9EC594888A0D18431&l=19476&s1=map" target="_blank" rel="noopener noreferrer" onClick={handleImageClick}>
          <img src={footerImage} alt="Footer" className="footer-image"/>
        </a>
      </div>
      <footer className="footer">
        <p>© 2024 FindItNext. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MapPage;