import React from 'react';

export const trackEvent = (event, params = {}) => {
  if (window.fbq) {
    window.fbq('track', event, params);
  }
};

const FacebookPixel = ({ children }) => {
  return <>{children}</>;
};

export default FacebookPixel;   