import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { database } from '../firebaseConfig';
import { ref, get } from "firebase/database";
import axios from 'axios';

function RedirectComponent() {
    const { redirectId } = useParams();
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserCountry = async () => {
            try {
                const response = await axios.get('https://api.ipgeolocation.io/getip');
                const ip = response.data.ip;
                const geoResponse = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}&ip=${ip}`);
                return geoResponse.data.country_code2;
            } catch (error) {
                return 'UNKNOWN'; // Резервний варіант
            }
        };

        const fetchRedirectData = async () => {
            try {
                const redirectRef = ref(database, `/redirects/${redirectId}`);
                const snapshot = await get(redirectRef);
                return snapshot.val();
            } catch (error) {
                console.error("Failed to fetch redirect data:", error);
                return null;
            }
        };

        const handleRedirect = async () => {
            const userCountry = await fetchUserCountry();
            const data = await fetchRedirectData();

            if (data && data.active) {
                const myCountries = data.myCountries || [];
                const yourUrl = data.yourUrl || "";

                if (myCountries.includes(userCountry)) {
                    setUrl(data.myurl);
                } else {
                    setUrl(yourUrl || "https://y20india.in/fun-facts/");
                }
            } else {
                setUrl("https://y20india.in/fun-facts/");
            }

            setLoading(false);
        };

        handleRedirect();
    }, [redirectId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (url) {
        window.location.href = url;
        return null;
    }

    return <div>Failed to redirect</div>;
}

export default RedirectComponent;