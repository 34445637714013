import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RedirectComponent from './components/RedirectComponent';
import MainPage from './pages/MainPage';
import MapPage from './pages/MapPage'; // Імпорт компонента сторінки з картою
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/r/:redirectId" element={<RedirectComponent />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/map" element={<MapPage />} /> {/* Новий маршрут для сторінки з картою */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;