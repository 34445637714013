import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import './MainPage.css'; // Додайте CSS для стилізації
import logo from '../assets/logo.png'; // Імпорт лого

function MainPage() {
  const [offers, setOffers] = useState([]);
  const userLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const fetchOffers = async () => {
      const db = getDatabase();
      const offersRef = ref(db, 'offers');
      const snapshot = await get(offersRef);
      const data = snapshot.val();
      
      // Перевірка наявності офферів для мови користувача
      const userOffers = data[userLanguage] || data.default;
      setOffers(userOffers);
    };

    fetchOffers();
  }, [userLanguage]);

  return (
    <div className="main-page">
      <img src={logo} alt="Logo" className="logo" /> {/* Додавання лого */}
      {offers.map((offer, index) => (
        <a key={index} href={offer.link} className="offer-card">
          <h2>{offer.title}</h2>
          <p>{offer.description}</p>
          <div className="offer-button">
            {offer.button_text}
          </div>
        </a>
      ))}
    </div>
  );
}

export default MainPage;
