import React, { useEffect } from 'react';
import L from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';  // Додайте цей імпорт для стилів GeoSearch
import './Map.css';  // Припускаю, що стилі для .map-container вже визначено

const Map = () => {
  useEffect(() => {
    let map;

    if (L.DomUtil.get('map') !== null) {
      L.DomUtil.get('map')._leaflet_id = null;
    }

    map = L.map('map').setView([51.505, -0.09], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Провайдер пошуку та контроль
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: 'bar',
      autoComplete: true,
      autoCompleteDelay: 250,
      showMarker: false, // Вимикаємо показ маркера
      showPopup: false,
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: 'Enter address',
      keepResult: false  // Вимикаємо збереження результату на карті
    });

    map.addControl(searchControl);

    return () => {
      map.remove();  // Забезпечує чистку після видалення компонента з DOM
    };
  }, []);

  return (
    <div id="map" className="map-container"></div>
  );
};

export default Map;