// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMXq96Ym_SWoMUjyEqUfskwXjsqNPFEGc",
  authDomain: "finditnext-83fc9.firebaseapp.com",
  projectId: "finditnext-83fc9",
  storageBucket: "finditnext-83fc9.appspot.com",
  messagingSenderId: "719961624417",
  appId: "1:719961624417:web:54248a730369589290bf20",
  measurementId: "G-KG2B5SBT4Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

// Initialize Database
const database = getDatabase(app);

export { database };